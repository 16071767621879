<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">
			<div class="h54 align-items-center">
				<div class="title-item center fz16 h100" :class="titleIndex == i ? 'co-010085' : 'co-333333'"
					v-for="(item, i) in titleList" :key="i" @click="titleChoose(i)">{{item}}</div>
			</div>

			<!-- 文章 -->
			<div class="padd15" v-if="titleIndex == 0">
				<div class="padd15 display-flex list-item" v-for="(item, i) in list" :key="i" @click="toDetail(item.id)">
					<img class="img_01 display-block" :src="item.image" alt="" />
					<div class="h100 flex-column justify-content-between h122 flex-1 marl20">
						<div>
							<div class="fz24 co-333333 font-blod s-content">{{item.title}}</div>
							<div class="fz16 co-333333 mart15" v-if="item.desc">{{item.desc}}</div>
						</div>
						<div class="fz16 co-999999 justify-content-center-between">
							<div class="align-items-center">
								<span class="">{{item.favorite || 0 }}推荐</span>
								<span class="marl40 display-block">{{item.comment || 0}}评论</span>
							</div>
							<div class="">{{item.create_time}}</div>
						</div>
						<div class="paddlr16 marb10" v-if="item.avatar || item.nickname">
							<div class="align-items-center ">
								
								<img class="icon_02 "  :src="item.avatar" alt="" />
								<text class="fz16 co-222222 font-blod marl10">{{item.nickname || ''}}</text>
							</div>
						</div>
					</div>
					
				</div>
			</div>

			<!-- 素材 -->
			<div class="mart4 paddlr30 " v-if="titleIndex == 1">
				<Material :list="list"></Material>
			</div>
			<div class="mart4 paddlr30 " v-if="titleIndex == 2">
				<div class="display-flex flex-wrap">
					<div class="item boderra8 bg-white marb20 cure-pointer paddb23" v-for="(item, i) in list" :key="i" @click="toDetail(item.id)">
						<div class="">
							<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
							<el-image class="item-pic display-block"  :src="item.image" lazy fit="cover"></el-image>
						</div>
						<div class="paddlr16 paddb23">
							<div class="paddt20 flex-wrap" v-if="item.tag">
								<div class="label bg-F7F7F7 center fz16 co-999999">{{item.tag}}</div>
							</div>
							<div class="mart20 fz20 co-333333 l20 one-point font-blod " v-if="item.title">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 院校指南 -->
			<div class="mart4 paddlr30 " v-if="titleIndex == 3">
				<div class="display-flex flex-wrap">
					<div class="item boderra8 bg-white marb20 cure-pointer" v-for="(item, i) in list" :key="i" @click="toDetail(item.id)">
						<div class="">
							<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
							<el-image class="item-pic display-block"  :src="item.image" lazy fit="cover"></el-image>
						</div>
						<div class="paddlr16">
							<div class="paddt20 paddb27 fz20 co-333333 one-point font-blod">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
			<loadMore :state="state" loading_icon></loadMore>
		</div>

		<div class="center paddt54 paddb15">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'MyCollection',
		data() {
			return {
				titleList: ['文章', '素材', '课程', '院校指南'], // 收藏导航
				titleIndex: 0, // 导航选中
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dataType: 'picture_favorite', //获取数据类型
				size: 10,
				maxnum:'' ,//总条数
			}
		},
		mounted() {
			
			
			this.getData()

		},
		methods: {
			getData() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 10;
				this.$req({		
					method: 'get',
					data:{
						type:this.dataType,
						page:this.page,
						size:size,
					},
					url: '/api/mapleCollection/get',
					success: res => {
						let list = res;
						this.list = list;
						if (list.length == size) {
							this.state = 0;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						
					}
				});
				//总条数
				this.$req({
					method: 'get',
					data: {
						type:this.dataType,
						show_page:1,
						
					},
					url: '/api/mapleCollection/get',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 导航选中
			titleChoose(e) {
				if(this.titleIndex == e) return
				this.titleIndex = e
				if(e == 0){
					this.dataType = 'picture_favorite'
				}else if(e == 1){
					this.dataType = 'source_favorite'
				}else if(e == 2){
					this.dataType = 'course_favorite'
				}else if(e == 3){
					this.dataType = 'school_favorite'
				}
				this.list = [];
				this.page = 0;
				this.state = 0;
				this.loading = false;
				this.getData();
			},
			// 跳转详情
			toDetail(id) {
				if (this.titleIndex == 0) {
					this.$router.push({
						path:'/Detail',
						query: { type: 5,
								id:id
						}
					})
				}if (this.titleIndex == 2) {
					this.$router.push({
						path:'/ClassDetail',
						query: { type: 2,
								id:id
						}
					})
				} 
				if (this.titleIndex == 3) {
					this.$router.push({
						path:'/Detail',
						query: { type: 3,
								id:id
						}
					})
				}
			},
			handleCurrentChange(val) {
			   this.state = 0;
			   this.page = val;
			   this.list = [];
			   this.loading = false;
			   this.getData();
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
			
		}
	}
</script>

<style lang="less" scoped>
	.icon_02 {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	.item {
		width: 285px;
		margin-right: 20px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		height: 100%;
		.item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
		
		.label {
			padding: 0 7px;
			height: 28px;
			border-radius: 4px;
		}
	}
	
	.item:nth-child(3n) {
		margin-right: 0;
	}
	.title-item {
		padding: 0 30px;
	}

	.title-item:hover {
		cursor: pointer;
		color: #010085;
	}

	.img_01 {
		width: 164px;
		height: 122px;
	}

	.list-item {
		border: 1px solid #DCFF03;
		border: 1px solid #FFFFFF;
	}

	.list-item:hover {
		cursor: pointer;
		border: 1px solid #DCFF03;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
	}

	.list-item-01 {
		width: 283px;
		border: 1px solid #E6E6E6;
		border-radius: 8px;

		.content {
			height: 58px;
		}
	}

	.list-item-01:nth-child(3n) {
		margin-right: 0;
	}

	.list-item-01:hover {
		border: 1px solid #DCFF03;
		cursor: pointer;
	}

	.list-item-01:hover .s-content {
		color: #010085;
	}

	.list-item-pic-01 {
		width: 285px;
		height: 220px;
		border-radius: 8px 8px 0px 0px;
	}

	.label-item {
		padding: 0 9px;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 4px;
	}

	.label-item-01 {
		padding: 0 9px;
		height: 28px;
		background: #F7F7F7;
		border-radius: 4px;
	}

	.de_see {
		width: 20px;
		height: 20px;
	}

	.slist-item-pic {
		width: 285px;
		height: 220px;
	}
</style>
